<template>
  <div>
    <div v-if="loading"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
    <div v-if="!loading">
      <h3>Tags</h3>
      <b-table id="table-tags" striped hover :items="tags" :fields="fields"></b-table>
      <h3>Topics</h3>
      <b-table id="table-topics" striped hover :items="topics" :fields="fields"></b-table>
    </div>
  </div>
</template>

<script>
export default {
  created: function () {
    this.load()
  },
  data () {
    return {
      fields: [
        { key: 'name', sortable: true },
        { key: 'accepted', sortable: true },
        { key: 'rejected', sortable: true },
        { key: 'pending', sortable: true },
        { key: 'total', sortable: true },
        { key: 'effectiveness', sortable: true }
      ],
      loading: true,
      tags: [],
      topics: []
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = '/suggest/stats/all'
        let response = await this.$Amplify.API.get(apiName, path)
        this.topics = response.topics
        this.tags = response.tags
        this.loading = false
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    }
  }
}
</script>

<style></style>

<template>
  <b-container class="mb-4" v-if="active">
    <b-row>
      <b-col>
      <div><b-badge variant="info"> {{notification.frequency}}</b-badge> <strong> {{notification.subject}}</strong></div>
      <b-form inline>
      <b-form-checkbox class="mr-4" v-model="showDetails" name="check-button" switch>
        show details
      </b-form-checkbox>
      <delete-standard
        :id="notification.id"
        :model="model"
        @deleted="hide"
      />
      </b-form>
      </b-col>
    </b-row>
    <b-row v-if="showDetails">
      <b-col>
        <strong>Topics</strong>
        <div v-if="!notification.config.filterByTopics">no filter</div>
        <div v-for="topic in topicsSelected" :key="'n' + notification.id + 'topic' + topic.id">
          {{topic.name}}
        </div>
      </b-col>
      <b-col>
        <strong>Sources</strong>
        <div v-if="!notification.config.filterBySources">no filter</div>
        <div v-for="source in notification.config.publishers" :key="'n' + notification.id + source">
          {{source}}
        </div>
      </b-col>
      <b-col>
        <strong>Tags</strong>
        <div v-if="!notification.config.filterByTags">no filter</div>
        <div v-for="tag in tagsSelected" :key="'n' + notification.id + 'tag' + tag.id">
          {{tag.name}}
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import DeleteStandard from '@/components/DeleteStandard.vue'

export default {
  name: 'NotificationItem',
  components: {
    DeleteStandard
  },
  props: ['model', 'notification', 'tags', 'topics'],
  created: function () {
    this.tagsSelected = _.map(this.notification.config.tags, x => {
      const item = _.find(this.tags, ['id', x])
      if (item) {
        return item
      }
    })
    this.topicsSelected = _.map(this.notification.config.topics, x => {
      const item = _.find(this.topics, ['id', x])
      if (item) {
        return item
      }
    })
  },
  data () {
    return {
      active: true,
      showDetails: false,
      tagsSelected: [],
      topicsSelected: []
    }
  },
  methods: {
    hide: function () {
      this.active = false
    }
  }
}
</script>
